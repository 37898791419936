import fetcher from 'src/utils/fetcher';

import { UpdateUserSkillLevelDTO } from 'src/models/applicant/resume/api/skills/dto';

export interface ChosenSkill {
    name: string;
}

interface UpdateRequest {
    userSkillLevels: UpdateUserSkillLevelDTO[];
}

declare global {
    interface FetcherPutApi {
        '/shards/applicant/key_skills/levels/update': {
            body: UpdateRequest;
            response: boolean;
            queryParams: void;
        };
    }
}

export const fetchRecommendedSkills = (resumeHash: string, chosenSkills: ChosenSkill[], signal?: AbortSignal) =>
    fetcher.post('/shards/recommended_skills', { resumeHash, chosenSkills }, { signal }).then(
        ({ data: { skillsSuggest } }) => skillsSuggest,
        () => []
    );

export const putKeySkillsLevelsUpdate = (userSkillLevels: UpdateUserSkillLevelDTO[], signal?: AbortSignal) =>
    fetcher.put('/shards/applicant/key_skills/levels/update', { userSkillLevels }, { signal }).then(() => true);
