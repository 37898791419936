import { AxiosResponse } from 'axios';

import { UpdateBodyType } from 'src/models/applicant/resume/editor/types';
import { EditorFieldError } from 'src/models/applicant/resume/editor/types/error';
import { ApplicantResumeDTO } from 'src/models/applicant/resume/types/resume';
import fetcher from 'src/utils/fetcher';

export interface ApiSuccess {
    resume: ApplicantResumeDTO;
}
export interface ApiConditionsErrors {
    errors: EditorFieldError[];
}

export type ResumeEmployersListType = 'whitelist' | 'blacklist';

declare global {
    interface FetcherGetApi {
        '/applicant/resume': {
            body: void;
            response: ApiSuccess;
            queryParams: { resume: string };
        };
    }
    interface FetcherPostApi {
        '/applicant/resume/edit': {
            body: UpdateBodyType;
            response: ApiSuccess | ApiConditionsErrors;
            queryParams: { resume: string; hhtmSource: string };
        };
        '/applicant/deleteresume': {
            body: { hash: string; hhtmFrom: string; hhtmSource: string };
            response: { url: string };
            queryParams: { from: string; hhtmFromLabel: string };
        };
        '/applicant/resumes/touch': {
            body: { resume: string; undirectable: boolean };
            response: void;
            queryParams: void;
        };
    }
}

export const ResumeApi = {
    get(resume: string, signal?: AbortSignal): Promise<ApiSuccess> {
        return fetcher.get('/applicant/resume', { params: { resume }, signal });
    },
    update(resume: string, hhtmSource: string, body: UpdateBodyType): Promise<ApiSuccess | ApiConditionsErrors> {
        return fetcher
            .post('/applicant/resume/edit', body, { params: { resume, hhtmSource } })
            .then(({ data }) => data);
    },
    delete(hash: string, hhtmFrom: string, hhtmSource: string): Promise<AxiosResponse<{ url: string }>> {
        return fetcher.postFormData(
            '/applicant/deleteresume',
            { hash, hhtmFrom, hhtmSource },
            { params: { from: 'resume-delete', hhtmFromLabel: 'resume-delete' } }
        );
    },
    touch(hash: string): Promise<AxiosResponse> {
        // логика поднятия резюме в поиске.
        return fetcher.postFormData('/applicant/resumes/touch', { resume: hash, undirectable: true });
    },
};
