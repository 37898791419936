import { AxiosResponse } from 'axios';

import fetcher from 'src/utils/fetcher';

import { ArtifactDTO, ArtifactsUploadDTO, ArtifactType } from 'src/models/applicant/resume/api/artifacts/dto';

declare global {
    interface FetcherGetApi {
        '/shards/applicant/artifacts/all': {
            body: void;
            response: { images: ArtifactDTO[] };
            queryParams: { type?: ArtifactType };
        };
    }
    interface FetcherPostApi {
        '/applicant/resumes/artifacts/remove': {
            body: void;
            response: void;
            queryParams: { id: number };
        };
        '/applicant/resumes/artifacts/upload': {
            body: { type: ArtifactType; file: File };
            response: ArtifactsUploadDTO;
            queryParams: void;
        };
    }
}

export const ArtifactsApi = {
    getArtifacts(type?: ArtifactType, abortSignal?: AbortSignal): Promise<ArtifactDTO[]> {
        return fetcher
            .get('/shards/applicant/artifacts/all', { params: { type }, signal: abortSignal })
            .then(({ images }) => images);
    },
    remove(artifactId: number, abortSignal?: AbortSignal): Promise<AxiosResponse<void>> {
        return fetcher.post('/applicant/resumes/artifacts/remove', undefined, {
            params: { id: artifactId },
            signal: abortSignal,
        });
    },
    upload(type: ArtifactType, file: File, abortSignal?: AbortSignal): Promise<AxiosResponse<ArtifactsUploadDTO>> {
        return fetcher.postFormData(
            '/applicant/resumes/artifacts/upload',
            { type, file },
            {
                signal: abortSignal,
            }
        );
    },
};
